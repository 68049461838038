'use client';

import { useActiveTemplate } from '@bloom/library/components/hooks/useActiveTemplate';

import MinimalistHomePage from '@bloom/portal/containers/public/pages/templates/minimalist/PhotographerProfile/Home';
import NordicHomePage from '@bloom/portal/containers/public/pages/templates/nordicAir/PhotographerProfile/Home';
import VerticalSplitHomePage from '@bloom/portal/containers/public/pages/templates/verticalSplit/PhotographerProfile/Home';

const LandingIndexPage: React.FC = () => {
  const activeTemplate = useActiveTemplate();

  switch (activeTemplate) {
    case 'nordic-air':
      return <NordicHomePage />;
    case 'minimalist':
      return <MinimalistHomePage />;
    case 'vertical-split':
      return <VerticalSplitHomePage />;
    default:
      return null;
  }
};

export default LandingIndexPage;
